<template>
  <div class="offer-info-block">
    <b-row>
      <b-col
        cols="12"
        style="background: #fff"
      >
        <b-row style="padding-bottom: 0.857rem;">
          <b-col cols="12">
            <label>Your postback URL</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-input
              v-model="postback_url"
            />
          </b-col>
          <b-col cols="12">
            <div class="offer-info-block-des">
              Your Server-to-Server webhook url to process user
              rewards on your frontend.
              This url will be called on each reward and screenout
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="6"
        style="background: #fff"
      >
        <b-row style="padding-bottom: 0.857rem;">
          <b-col cols="12">
            <label>Available parameters</label>
          </b-col>
        </b-row>
        <table class="table b-table table-striped table-hover border">
          <thead>
            <tr>
              <th>Parameter</th>
              <th>Type</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="(tr, indextr) in supportedParameters"
              :key="indextr"
              @click="addParameter(tr)"
            >
              <td style="cursor: pointer">
                {{ supportedParameters[indextr].key }}
              </td>
              <td>{{ supportedParameters[indextr].type }}</td>
              <td>
                {{ supportedParameters[indextr].description }}
              </td>
            </tr>
          </tbody>
        </table>

      </b-col>
      <b-col cols="6">
        <div
          style="font-weight: 600; font-size: 1.143rem; line-height: 1.429rem;"
          class="my-2"
        >
          Postback Test
        </div>
        <b-row class="mb-2">
          <b-col cols="6">
            <label for="test_unique_user_id">Unique user id</label>
            <b-form-input
              id="test_unique_user_id"
              v-model="test_unique_user_id"
              placeholder="Unique user id"
            />
          </b-col>
          <b-col cols="6">
            <label for="test_amount">Amount</label>
            <b-form-input
              id="test_amount"
              v-model="test_amount"
              placeholder="Amount"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <label for="userRewardAmount">User reward</label>
            <b-form-input
              id="userRewardAmount"
              v-model="userRewardAmount"
              readonly
              placeholder="User reward"
            />
            <div style="font-size: 0.857rem; line-height: 1.143rem; margin-top: 0.857rem">
              According to "Currency conversion rate"
            </div>
          </b-col>
          <b-col cols="6">
            <label>Type</label>
            <b-radio-group
              v-model="test_click_type"
              style="margin-top: 0.429rem;"
            >
              <b-radio :value="1">
                Complete
              </b-radio>
              <b-radio :value="2">
                Screenout
              </b-radio>
            </b-radio-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12">
            <b-button
              variant="primary"
              @click="test_postback()"
            >
              Test Postback
            </b-button>
          </b-col>
        </b-row>
        <div style="font-weight: 600; font-size: 1.143rem; line-height: 1.429rem; margin: 1.714rem 0">
          Postback Results
        </div>
        <b-row>
          <b-col cols="8">
            <label style="margin-bottom: 0.857rem">Postback url fired</label>
            <b-input
              v-model="test_fired_url"
              readonly="readonly"
              style="background-color: #FFF; margin-bottom: 0.857rem;"
            />
            <div>{{ test_description }}</div>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="12">
            <div
              v-if="test_status_code >= 0"
              style="text-align: center"
              :class="test_status_class"
            >
              <div class="w-full">
                <b style="font-size: 1.286rem">{{ test_status_code }}</b>
              </div>
              <div
                v-if="test_status_text != ''"
                class="w-full"
              >
                {{ test_status_text }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      postback_url: '',
      mounted: false,
      supportedParameters: [
        {
          key: '{reward}',
          type: 'Integer',
          description:
              'The amount of in-app currency that should be rewarded to the user',
        },
        {
          key: '{payout}',
          type: 'Integer',
          description: 'Publisher Payout in USD',
        },
        {
          key: '{type}',
          type: 'Integer',
          description: '1 = complete, 2 = screenout, 3 = extension bonus',
        },
        {
          key: '{user}',
          type: 'String',
          description: 'Unique User Id',
        },
        {
          key: '{tx_id}',
          type: 'String',
          description: 'Unique Transaction ID',
        },
        {
          key: '{subid}',
          type: 'String',
          description: 'Returns the subid in case you have an API integration',
        },
      ],
      test_unique_user_id: null,
      test_amount: null,
      test_user_reward: null,
      test_click_type: 1,
      test_status_class: null,
      test_status_code: -1,
      test_status_text: null,
      test_fired_url: '',
    }
  },
  computed: {
    postback_url_fired() {
      let postbackUrlFired = ''
      postbackUrlFired += this.postback_url
      const userUniqueId = this.test_unique_user_id === null
      || this.test_unique_user_id.trim() === ''
        ? ''
        : encodeURIComponent(this.test_unique_user_id.trim())
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{user}',
        userUniqueId,
      )
      let testAmount = this.test_amount === null ? 0 : parseFloat(this.test_amount)
      if (Number.isNaN(testAmount) || testAmount < 0) {
        testAmount = 0.0
      }
      testAmount = testAmount.toFixed(2)
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{payout}',
        encodeURIComponent(this.test_amount),
      )
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{reward}',
        encodeURIComponent(this.userRewardAmount),
      )
      postbackUrlFired = postbackUrlFired.replaceAll(
        '{type}',
        encodeURIComponent(this.test_click_type),
      )
      return postbackUrlFired
    },
    userRewardAmount() {
      let testAmount = this.test_amount === null ? 0 : parseFloat(this.test_amount)
      if (Number.isNaN(testAmount) || testAmount < 0) {
        testAmount = 0.0
      }
      const rewardAmount = this.value.currency_exchange_rate === null
        ? 0
        : parseInt(
          parseFloat(testAmount)
              * parseInt(this.value.currency_exchange_rate),
        )
      if (Number.isNaN(rewardAmount) || rewardAmount <= 0) {
        return 0
      }
      return rewardAmount
    },
    test_description() {
      if (this.test_status_code >= 0) {
        return 'Result server code'
      }
      return 'Click test postback to check result'
    },
  },
  watch: {
    postback_url() {
      const appData = this.value
      appData.postback_url = this.postback_url
      this.$emit('input', appData)
    },
    'value.postback_url': () => {
      this.init_data()
    },
  },
  mounted() {
    this.init_data()
    this.mounted = true
  },
  methods: {
    reset_data() {
      this.$emit('reset', ['postback_url'])
    },
    init_data() {
      this.postback_url = this.value.postback_url
      if (this.postback_url === null) this.postback_url = ''
    },
    test_postback() {
      if (
        (this.postback_url.indexOf('http://') !== 0
              && this.postback_url.indexOf('https://') !== 0)
      ) {
        this.alertError('Invalid url')
        return
      }
      this.showLoading()
      this.$http.post('/api/apps/test-postback', {
        url: this.postback_url_fired,
      }).then(response => {
        this.hideLoading()
        const returnData = response.data
        this.test_status_code = returnData.code
        this.test_status_text = ''
        if (returnData.code === 200) {
          this.test_status_class = 'text-success'
          this.test_status_text = 'Success'
        } else if (returnData.code < 400) {
          this.test_status_class = 'text-warning'
        } else {
          this.test_status_class = 'text-danger'
        }
        this.test_fired_url = this.postback_url_fired
      }).catch(errors => {
        this.hideLoading()
        this.alertError(errors.response.data)
      })
    },
    addParameter(param) {
      this.postback_url += param.key
    },
  },
}
</script>

<style scoped lang="scss">
.vs-dialog-text {
  overflow: auto;
  max-height: 14.286rem;
  word-break: break-all;
}

.vs-con-textarea {
  margin-bottom: 0.5rem;
}

.offer-info-block {
  background: #F8F8F8;
  border-radius: 0.429rem;
  margin-bottom: 0.857rem;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    color: #5E5873;
  }
}

.offer-info-block-des {
  font-style: normal;
  font-weight: normal;
  font-size: 0.857rem;
  line-height: 1.143rem;
  color: #6E6B7B;
  margin-top: 0.857rem;
  margin-bottom: 2.286rem;
}
</style>
