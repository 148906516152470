<template>
  <div id="app-integration">
    <b-card
      v-if="value.status !== 1"
      class="block-test-mode"
      no-body
    >
      <b-card-body style="padding: 0.857rem 1.214rem;">
        <div class="text-test-mode">
          <b-img
            :src="require('@/assets/images/icons/fi_info.svg')"
            style="position: absolute; transform: translate(0, -50%); top: 50%; left: 1.214rem; width: 1.214rem;"
          />
          <span style="display: inline-block; margin-left: 2.143rem; line-height: 130%;">
            Your app is test mode.
            <template v-if="value.status === 2">
              <br>Our Team is reviewing your Integration.
            </template>
          </span>
        </div>
        <b-button
          v-if="value && value.status === 0"
          id="go-live-btn"
          :variant="btnGoliveVariant"
          @click="onClickRequestGolive"
        >
          Request to go live
        </b-button>
        <b-tooltip
          v-if="btnGoliveVariant === 'secondary'"
          target="go-live-btn"
          placement="bottom"
          triggers="hover"
        >
          Please complete the app setup first
        </b-tooltip>
      </b-card-body>
    </b-card>
    <div class="detail-offer-wall">
      <b-row>
        <b-col cols="12">
          <b-row class="mb-1">
            <b-col>
              <div class="block-warning-unique-id">
                <div>
                  <b-img
                    :src="require('@/assets/images/icons/fi_info.svg')"
                    style="position: absolute;transform: translate(0, -50%);top: 50%; left: 1.214rem; width: 1.75rem;"
                  />
                  <div style="display: inline-block; margin-left: 2.5rem; margin-bottom: 0.5rem;">
                    UNIQUE-USER-ID needs to be replaced by a unique user id which is static for the
                    individual user
                  </div>
                </div>
                <div>
                  <div style="display: inline-block; margin-left: 2.5rem;">
                    Please put {cid} placeholder in redirect url
                    that will be replaced dynamically fe. https://www.example.com/surveys?id={cid}
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <div
                class="offer-info-block p-1"
                style="border-left-width: 0.357rem !important;"
              >
                <b-row>
                  <b-col
                    cols="6"
                    style="padding-bottom: 0.857rem;"
                  >
                    <b-media no-body>
                      <b-media-aside class="mr-1">
                        <b-avatar
                          size="32"
                          variant="light-primary"
                        >
                          <feather-icon
                            size="16"
                            icon="CornerDownLeftIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <div class="offer-info-block-title">
                          Your redirect url
                        </div>
                      </b-media-body>
                    </b-media>
                  </b-col>

                  <b-col
                    cols="6"
                    style="padding-bottom: 0.857rem;"
                    align-self="center"
                  >
                    <div class="offer-info-block-title">
                      Available parameters
                    </div>
                  </b-col>

                  <b-col cols="6">
                    <b-input
                      v-model="redirect_url"
                      style="max-width: 50rem"
                    />
                  </b-col>
                  <b-col cols="6">
                    <table class="table b-table table-striped table-hover border">
                      <thead>
                        <tr>
                          <th>Parameter</th>
                          <th>Type</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody role="rowgroup">
                        <tr
                          v-for="(tr, indextr) in supportedParameters"
                          :key="indextr"
                          @click="addParameter(tr)"
                        >
                          <td style="cursor: pointer">
                            {{ supportedParameters[indextr].key }}
                          </td>
                          <td>{{ supportedParameters[indextr].type }}</td>
                          <td>
                            {{ supportedParameters[indextr].description }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <div
                class="offer-info-block p-1"
                style="border-left-width: 0.357rem !important;"
              >
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="32"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="16"
                        icon="CodeIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <div class="offer-info-block-title">
                      Script Tag
                    </div>
                    <p class="offer-info-block-des">
                      The script tag will show an icon on the bottom-right of your page which the
                      user can click to open the
                      widget showing the offerwall
                    </p>
                  </b-media-body>
                </b-media>
                <div class="code-copy-block">
                  <div
                    class="button-copy bg-primary"
                    @click="copyText(integration_script)"
                  >
                    <span>Copy</span></div>
                  <div class="code-content bg-light-primary">
                    <pre class="code-block"><span class="hljs-tag">&lt;<span
                      class="hljs-name"
                      style="font-weight: 700;"
                    >script</span> <span
                      class="hljs-attr"
                    >type</span>=<span
                      class="hljs-string"
                      style="color: rgb(136, 0, 0);"
                    >"text/javascript"</span>&gt;</span><span
                        class="actionscript"
                    >{<span
                        class="hljs-keyword"
                        style="font-weight: 700;"
                    >var</span> primeSurveysConfig = {app: <span
                        class="hljs-string"
                        style="color: rgb(136, 0, 0);"
                    >"{{
                        app_hash
                      }}"</span>, uuid: <span
                        class="hljs-string"
                        style="color: rgb(136, 0, 0);"
                    >"UNIQUE-USER-ID"</span>, cid: <span
                        class="hljs-string"
                        style="color: rgb(136, 0, 0);"
                    >"CID"</span>}}</span><span
                        class="hljs-tag"
                    >&lt;/<span
                        class="hljs-name"
                        style="font-weight: 700;"
                    >script</span>&gt;</span><br><span
                        class="hljs-tag"
                    >&lt;<span
                        class="hljs-name"
                        style="font-weight: 700;"
                    >script</span> <span
                        class="hljs-attr"
                    >src</span>=<span
                        class="hljs-string"
                        style="color: rgb(25, 61, 245);"
                    >"{{ base_url }}/primewidget.js"</span>/&gt;</span><span
                        class="undefined"
                    /><span class="hljs-tag">&lt;/<span
                        class="hljs-name"
                        style="font-weight: 700;"
                    >script</span>&gt;</span></pre>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <div
                class="offer-info-block p-1"
                style="border-left-width: 0.357rem !important;"
              >
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="32"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="16"
                        icon="FramerIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <div class="offer-info-block-title">
                      IFrame
                    </div>
                    <p class="offer-info-block-des">
                      The iFrame can be integrated to a separate page of your project to show the
                      offerwall right there
                    </p>
                  </b-media-body>
                </b-media>

                <div class="code-copy-block">
                  <div
                    class="button-copy bg-primary"
                    @click="copyText(integration_iframe)"
                  >
                    <span>Copy</span></div>
                  <div class="code-content bg-light-primary">
                    <pre class="code-block"><span class="hljs-tag">&lt;<span
                      class="hljs-name"
                      style="font-weight: 700;"
                    >iframe</span> <span
                      class="hljs-attr"
                    >src</span>=<span
                      class="hljs-string"
                      style="color: rgb(25 61 245);"
                    >"{{ base_url }}/?app=<span
                      style="color: rgb(136, 0, 0);"
                    >{{ app_hash }}</span>&amp;uuid=<span
                      style="color: rgb(136, 0, 0);"
                    >UNIQUE-USER-ID</span>&amp;cid=<span
                      style="color: rgb(136, 0, 0);"
                    >CID</span>"</span>&gt;</span><span class="hljs-tag">&lt;/<span
                      class="hljs-name"
                      style="font-weight: 700;"
                    >iframe</span>&gt;</span></pre>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape,no-useless-concat */
export default {
  name: 'AppEditRedirects',
  props: [
    'value',
  ],
  data() {
    return {
      integration_script: '',
      integration_iframe: '',
      base_url: null,
      app_hash: null,
      redirect_url: '',
      supportedParameters: [
        {
          key: '{status}',
          type: 'Integer',
          description: '1 = complete, 2 = screenout',
        },
        {
          key: '{cid}',
          type: 'String',
          description: 'Prime Surveys Unique Id, pass to the iFrame to show user the exact outcome',
        },
        {
          key: '{subid}',
          type: 'String',
          description: 'Returns the subid in case you have an API integration',
        },
      ],
    }
  },
  computed: {
    btnGoliveVariant() {
      let isValidReward = false
      if (this.value.reward_type !== 0) isValidReward = true
      else if (this.value.fixed_reward) isValidReward = true
      if (this.value.currency_exchange_rate && this.value.currency_name && isValidReward) return 'primary'
      return 'secondary'
    },
  },
  watch: {
    redirect_url() {
      const appData = this.value
      appData.redirect_url = this.redirect_url
      this.$emit('input', appData)
    },
  },
  mounted() {
    window.AppEditIntegration = this
    this.app_hash = this.value.hash
    this.base_url = process.env.VUE_APP_SURVEY_BASE_URL
    const SURVEY_BASE_URL = process.env.VUE_APP_SURVEY_BASE_URL

    this.integration_script = `${'<script type="text/javascript">'
        + '{var primeSurveysConfig = {app: "'}${this.value.hash}", uuid: "UNIQUE-USER-ID"}}`
        + '<\/script>' + `\n<script src="${SURVEY_BASE_URL}/primewidget.js"/><\/script>`
    this.integration_iframe = `<iframe src="${SURVEY_BASE_URL}/?app=${this.value.hash}&uuid=UNIQUE-USER-ID"></iframe>`
    this.redirect_url = this.value.redirect_url || ''
  },
  methods: {
    requestToMakeAppLive() {
      this.showLoading()
      this.$http
        .post(`/api/app/${this.$route.params.appHash}/request-live`)
        .then(response => {
          const returnData = response.data
          if (returnData.status) {
            this.$emit('request-live', returnData.status)
            this.value.status = returnData.status
          }
        })
        .catch(errors => {
          this.alertError(errors.response.data)
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    onClickRequestGolive() {
      if (this.btnGoliveVariant !== 'secondary') this.requestToMakeAppLive()
    },
    addParameter(param) {
      this.redirect_url += param.key
    },
  },
}
</script>

<style scoped lang="scss">
#app-integration {
  .code-copy-block {
    position: relative;
    background: rgba(115, 103, 240, 0.12);
    border-radius: 0.429rem;
    overflow: hidden;

    & > .button-copy {
      width: 5.571rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      color: #FFF;
      text-align: center;
      cursor: pointer;

      span {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    & > .code-content {
      flex: 1;
      padding: 0.714rem;
      padding-left: 6.286rem;
    }
  }

  .code-block {
    display: block;
    white-space: initial;
    background: none;
    font-size: 1rem;
    margin-bottom: 0;
  }
}

#app-integration .code-copy-block > .code-content > pre > span {
  display: inline !important;
}

.tab-title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.143rem;
  line-height: 1.429rem;
}

.tab-subtitle-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  width: 18.929rem;
}

.offer-info-block {
  background: #F8F8F8;
  border-radius: 0.429rem;
}

.offer-info-block-title {
  font-weight: 600;
  font-size: 1.071rem;
  line-height: 1.286rem;
  color: #5E5873;
}

.offer-info-block-des {
  width: 35.357rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;
  color: #6E6B7B;
}

.block-test-mode {
  background: rgba(234, 84, 85, 0.12);
  border-radius: 0.429rem;
  margin-bottom: 2.5rem;

  .text-test-mode {
    display: inline;
    margin-right: 2rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 120%;
    color: #EA5455
  }
}

.block-warning-unique-id {
  position: relative;
  background: rgba(234, 84, 85, 0.12);
  border-radius: 0.429rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  color: #EA5455;
  padding: 0.857rem 1.214rem;
}

.offer-type-recommend {
  position: absolute;
  right: 0.714rem;
  color: #fff;
  top: -0.714rem;
  padding: 0.357rem 0.714rem;
  font-weight: 600;
  font-size: 0.857rem;
  line-height: 1.071rem;
  background: #6E6B7B;
  border-radius: 1.429rem;
}

.nav-link.active .offer-type-recommend {
  background: #7367F0;
}
</style>
<style>

[dir] .offer-type .nav-pills .nav-link {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: none;
  padding: 0.857rem;
  border-radius: 0.571rem;
}

[dir] .offer-type .nav-pills .nav-link.active {
  color: #7367F0;
  border: 1px solid #7367F0;
}

[dir] .offer-type.tabs .nav .nav-item {
  margin: 0 0.571rem !important;
  border-radius: 0.714rem !important;
}

svg.tab-title-icon {
  margin-right: 0 !important;
}

.offer-type .nav-link .tab-title-text {
  color: #6E6B7B;
}

.offer-type .nav-link.active .tab-title-text {
  color: #7367F0;
}
</style>
